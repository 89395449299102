import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { motion, AnimatePresence } from "framer-motion";
import Nav from "../Nav";
import "../../styles/style.css";
import "./styles.css";

const duration = 0.25;

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
};

const Layout = ({ children, location }) => {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    const value = theme === "dark" ? "light" : "dark";
    setTheme(value);
    localStorage.setItem("theme", value);
  };

  useEffect(() => {
    const themeValue = localStorage.getItem("theme");
    if (themeValue) {
      setTheme(themeValue);
    }
  }, []);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>Pablo Jurado | Front End Engineer</title>
        <meta
          name="description"
          content="This is Pablo Jurado's personal blog"
        />
        <link rel="canonical" href="https://pablojurado.com" />
      </Helmet>
      <div className={theme}>
        <Nav toggleTheme={toggleTheme} theme={theme} location={location} />
        <AnimatePresence>
          <motion.main
            key={location.pathname}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <div className="wrapper">{children}</div>
          </motion.main>
        </AnimatePresence>
      </div>
    </>
  );
};

export default Layout;
