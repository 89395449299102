import React from "react";
import "./styles.css";

const Sun = () => (
  <svg
    height="20px"
    fill="#3d4351"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
    ></path>
  </svg>
);

const Moon = () => (
  <svg
    height="20px"
    fill="#3d4351"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
    ></path>
  </svg>
);

const ToggleThemeButton = ({ theme, toggleTheme }) => {
  const isDark = theme === "dark";

  return (
    <button aria-label="Toggle Theme" title="Toggle Theme" className="toggle-theme" onClick={toggleTheme}>
      {isDark ? <Sun /> : <Moon />}
    </button>
  );
};

export default ToggleThemeButton;
