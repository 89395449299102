import React, { useState } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";
import { Link } from "gatsby";
import ActiveLink from "../ActiveLink";
import ToggleThemeButton from "../ToggleThemeButton";
import "./styles.css";

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "-100%" },
};

const Nav = ({ toggleTheme, theme, location }) => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const navData = [
    {
      name: "Home",
      href: "/",
    },
    {
      name: "About Me",
      href: "/about-me/",
    },
    {
      name: "Blog",
      href: "/blog/",
    },
  ];

  return (
    <nav>
      <div className="desktop-nav">
        <AnimateSharedLayout>
          <ul>
            {navData.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.href}
                  className={location.pathname === item.href ? "active" : null}
                >
                  {item.name}
                </Link>
                {location.pathname === item.href && (
                  <motion.div layoutId="underline">
                    <ActiveLink />
                  </motion.div>
                )}
              </li>
            ))}
          </ul>
        </AnimateSharedLayout>

        <button className="btn-only-mobile" onClick={() => setMobileMenu(true)}>
          Menu
        </button>

        <ToggleThemeButton toggleTheme={toggleTheme} theme={theme} />
      </div>

      <motion.div
        className="mobile-nav-wrapper"
        initial={{ x: "-100%" }}
        animate={mobileMenu ? "open" : "closed"}
        transition={{ duration: 0.5 }}
        variants={variants}
      >
        <button aria-label="Close Menu" onClick={() => setMobileMenu(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            height="20px"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <ul className="mobile-nav">
          {navData.map((item) => (
            <li key={item.name}>
              <Link onClick={() => setMobileMenu(false)} to={item.href}>
                {item.name}
              </Link>
              {location.pathname === item.href && (
                <ActiveLink style={{ height: "10px" }} />
              )}
            </li>
          ))}
        </ul>
      </motion.div>
    </nav>
  );
};

export default Nav;
